import _ from 'lodash';
import qs from 'qs';
import React, { useState } from 'react';
import { DialogContent, FormHelperText } from '@mui/material';

import { Redirect, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// components
import ReactCodeInput from 'react-code-input';

import { withStyles } from '@mui/styles';
import { SquareButton } from '../Buttons/SquareButton';
import PublicComponentWrapper from '../wrappers/PublicComponentWrapper';
import { withFormController } from '../hocs/forms';
import { InputLabelBase } from '../inputs/InputLabelBase';
import CbButton from '../Buttons/CbButton';

// actions
import {
  sendCodeToRetail,
  getRetailAccessToken,
  getRedirectUrl,
  resendPhoneCode,
} from '../../api/AuthService';
import { useActor } from '../hooks/useActor';

import { flowUserAuthenticated } from '../../store/actions/user.store.actions';
import { useToggleModal } from '../../utils/modal.utils';

const CodeInput = withFormController(ReactCodeInput);

const styles = ({ config, palette }) => {
  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '2.5rem 0',
      marginLeft: '5rem',
    },
    code: {
      display: 'flex',
      flexDirection: 'column',
    },
    in: {
      whiteSpace: 'nowrap',
      '& input': {
        borderRadius: 5,
        border: `solid 1px ${config.colors.almostWhite}`,
        background: palette.common.darkBlue,
        marginTop: 5,
        marginLeft: 5,
        color: config.colors.white,
        fontSize: config.textSizes.paragon,
        textAlign: 'center',
        width: 36,
        height: 42,
      },
    },
    label: {
      padding: '0 0 0 1rem',
      fontSize: config.textSizes.normal,
      color: config.colors.white,
    },
    contact: {
      margin: '5rem',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    href: {
      color: config.colors.cowbellBlue,
      textAlign: 'center',
    },
    margins: {
      margin: '5rem 0',
      fontSize: config.textSizes.paragon,
    },
    fonts: {
      fontSize: config.textSizes.canon,
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '2.5rem',
      flexDirection: 'column',
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '0.416rem',
      marginLeft: '3.75rem',
    },
  };
};

const iconStyle = {
  width: '2rem',
  height: '2rem',
};

export const AccessCodeTemp = withStyles(styles)(({ classes, match }) => {
  const toggleModal = useToggleModal();

  const {
    formState: { isSubmitting },
    handleSubmit,
    ...methods
  } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [redirectURL, setUrl] = useState('');
  const [login, setLogin] = useState(false);

  const { workflowId } = match.params;

  const actor = useActor();

  /**
   * Component Mount
   *  - Send the code
   *  - Set the user and account in store
   */
  React.useEffect(() => {
    sendingCode();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (actor.isAgency && redirectURL) {
      if (data.flow === 'SURPLUS_NOTICE') {
        return <Redirect to={redirectURL} />;
      }

      history.replace({
        pathname: redirectURL,
        search: qs.stringify(
          { agency: actor?.account?.name },
          { addQueryPrefix: true }
        ),
      });
    }

    if (redirectURL) {
      history.replace(redirectURL);
    }
    // eslint-disable-next-line
  }, [data, redirectURL]);

  function ResentCodeModal(sendCodeTo) {
    if (sendCodeTo === 'email') {
      sendingCode();
    } else {
      resendPhoneCode(workflowId);
    }
    data.sendCodeTo = sendCodeTo;

    toggleModal.direct(
      'SecurityCodeSent',
      { ...data },
      {
        title: 'Security Code Sent',
      }
    );
  }

  function sendingCode() {
    sendCodeToRetail(workflowId)
      .then((resp) => {
        dispatch(flowUserAuthenticated(resp.data));
        setData(resp.data);
      })
      .catch((error) => {
        if (error.response?.status === 500 || error.response?.status === 502) {
          setError('Site Under Maintainence!');
        } else {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Not able to authenticate the user.'
              )
            )
          );
        }
      });
  }

  const onSubmit = (formValues) => {
    return getRetailAccessToken({
      workflowId,
      secret: formValues.accessCode,
    })
      .then(() => {
        getRedirectUrl(workflowId)
          .then((resp) => {
            setUrl(resp.data.redirectURL);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setLogin(true);
            } else {
              setError(
                _.get(
                  error.response,
                  'data.message',
                  _.get(
                    error.response,
                    'data',
                    'Not able to authenticate the user.'
                  )
                )
              );
            }
          });
      })

      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(error.response, 'data', 'Not able to authenticate the user.')
          )
        );
      });
  };

  if (login) {
    return <Redirect to="/login" />;
  }

  return (
    <PublicComponentWrapper>
      {data && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <div
                className="modal-title"
                style={{ whiteSpace: 'nowrap', color: 'white' }}
              >
                <p className={classes.fonts}>
                  Please click on Send Code Icons (email or text message) to get
                  a one-time 6-digit code
                </p>
                <p className={classes.margins}>
                  Enter your one-time, 6-digit code to log in
                  {data.flow === 'E_PAY' && (
                    <>
                      to access
                      <br />
                      payment for {data.flowAccountName}
                    </>
                  )}
                  {data.flow === 'SURPLUS_NOTICE' && 'to reviw and sign'}
                  {data.flow !== 'E_PAY' &&
                    data.flow !== 'SURPLUS_NOTICE' &&
                    'to access the Quote'}
                </p>

                <div className={classes.wrapper}>
                  <div className={classes.code}>
                    <InputLabelBase required className={classes.label}>
                      Security Code
                    </InputLabelBase>
                    <CodeInput
                      name="accessCode"
                      type="text"
                      autoFocus
                      className={classes.in}
                      fields={6}
                    />

                    <div className={classes.buttonWrapper}>
                      <CbButton
                        loading={isSubmitting}
                        styleName="ctaButton"
                        buttonText="Enter"
                        type="submit"
                      />
                      <FormHelperText className="api-text-error">
                        {error}
                      </FormHelperText>
                    </div>
                  </div>

                  <div>
                    <InputLabelBase
                      className={classes.label}
                      style={{ marginLeft: '3.2rem' }}
                    >
                      Re-Send Code
                    </InputLabelBase>
                    <div className={classes.iconWrapper}>
                      <SquareButton
                        variant="contained"
                        onClick={() => ResentCodeModal('email')}
                        style={{ marginRight: '2rem' }}
                      >
                        <img
                          src="images/Email_ReSendCode.svg"
                          alt="Resend Code"
                          style={iconStyle}
                        />
                      </SquareButton>
                      <SquareButton
                        variant="contained"
                        onClick={() => ResentCodeModal('phone')}
                        disabled={!data.flowUserPhone}
                      >
                        <img
                          src="images/Text_ReSendCode.svg"
                          alt="Resend Code"
                          style={iconStyle}
                        />
                      </SquareButton>
                    </div>
                  </div>
                </div>
              </div>

              <p className={classes.contact}>
                Please contact{' '}
                <a
                  style={{ color: '#2180e2', textAlign: 'center' }}
                  href="mailto:support@cowbellcyber.ai"
                >
                  support@cowbellcyber.ai
                </a>{' '}
                if you have any issues retrieving the quote.
              </p>
            </DialogContent>
          </form>
          <>{actor.isAgency && redirectURL && <div>Loading</div>}</>
        </FormProvider>
      )}
    </PublicComponentWrapper>
  );
});
