import { trimAndAddAsterisk } from '../../utility/QueryUtility';
import { commonDateParamMappings } from '../common/mappings';

export const SUBMISSIONS_QUERY_PARAM_MAPPING = [
  ...commonDateParamMappings,
  { path: 'page' },
  { path: 'size' },
  { path: 'dateFilter' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { orderBy: string; order: string }) => {
      // map the column we are sorting on to its BE key
      const _orderBy = SORT_KEY_MAPPING[orderBy] ?? orderBy;

      if (_orderBy && order) {
        switch (_orderBy) {
          default:
            return `${_orderBy},${order}`;
        }
      }
      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search = '' }) =>
      !search ? search : trimAndAddAsterisk(search),
  },
  { path: 'f', paramKey: 'filter' },
];

const SORT_KEY_MAPPING = {
  accountName: 'firmographics.name',
  agencyId: 'agencyAccount.agencyId',
  accountId: 'agencyAccount.accountId',
} as Record<string, string>;
